/* Checkout */
export const SET_CHECKOUT_FORM_ADDRESS = "SET_CHECKOUT_FORM_ADDRESS";
export const SET_CHECKOUT_FORM_BRANCH = "SET_CHECKOUT_FORM_BRANCH";
export const SET_CHECKOUT_FORM_CITY = "SET_CHECKOUT_FORM_CITY";
export const SET_CHECKOUT_FORM_COUNTRY = "SET_CHECKOUT_FORM_COUNTRY";
export const SET_CHECKOUT_FORM_DELIVERY = "SET_CHECKOUT_FORM_DELIVERY";
export const SET_CHECKOUT_FORM_EMAIL = "SET_CHECKOUT_FORM_EMAIL";
export const SET_CHECKOUT_FORM_NAME = "SET_CHECKOUT_FORM_NAME";
export const SET_CHECKOUT_FORM_NOTES = "SET_CHECKOUT_FORM_NOTES";
export const SET_CHECKOUT_FORM_PHONE_NUMBER = "SET_CHECKOUT_FORM_PHONE_NUMBER";
export const SET_CHECKOUT_FORM_PICKUP = "SET_CHECKOUT_FORM_PICKUP";
export const SET_CHECKOUT_FORM_STATE = "SET_CHECKOUT_FORM_STATE";
export const SET_CHECKOUT_FORM_ZIP_CODE = "SET_CHECKOUT_FORM_ZIP_CODE";
export const SET_CHECKOUT_FORM_GOOGLEMAPS = "SET_CHECKOUT_FORM_GOOGLEMAPS";
export const SET_CHECKOUT_HELLO_MESSAGE = "SET_CHECKOUT_HELLO_MESSAGE";
export const SET_CHECKOUT_MESSAGE_ENDING = "SET_CHECKOUT_MESSAGE_ENDING";
export const SET_CHECKOUT_PHONE_NUMBER = "SET_CHECKOUT_PHONE_NUMBER";
export const SET_CHECKOUT_PRICE_FIELD = "SET_CHECKOUT_PRICE_FIELD";

/* Branches */
export const SET_CHECKOUT_ADD_BRANCH = "SET_CHECKOUT_ADD_BRANCH";
export const SET_CHECKOUT_UPDATE_BRANCH = "SET_CHECKOUT_UPDATE_BRANCH";
export const SET_CHECKOUT_REMOVE_BRANCH = "SET_CHECKOUT_REMOVE_BRANCH";
export const SET_CHECKOUT_SET_BRANCHES = "SET_CHECKOUT_SET_BRANCHES";

/* Custom payment options */
export const SET_CHECKOUT_FORM_PAYMENT_METHODS =
  "SET_CHECKOUT_FORM_PAYMENT_METHODS";
export const SET_CHECKOUT_FORM_PAYMENT_OPTIONS_CASH =
  "SET_CHECKOUT_FORM_PAYMENT_OPTIONS_CASH";
export const SET_CHECKOUT_FORM_PAYMENT_OPTIONS_CARD =
  "SET_CHECKOUT_FORM_PAYMENT_OPTIONS_CARD";
export const SET_CHECKOUT_SET_PAYMENT_OPTIONS =
  "SET_CHECKOUT_SET_PAYMENT_OPTION";

/* Variants */
export const ADD_PRODUCT_OPTION = "ADD_PRODUCT_OPTION";
export const DELETE_PRODUCT_OPTION = "DELETE_PRODUCT_OPTION";
export const SET_PRODUCT_OPTIONS = "SET_PRODUCT_OPTIONS";
export const UPDATE_PRODUCT_OPTION = "UPDATE_PRODUCT_OPTION";

/* Single page config */
export const SET_DETAIL_CONFIG = "SET_DETAIL_CONFIG";
export const SET_DETAIL_CONFIG_DESCRIPTION = "SET_DETAIL_CONFIG_DESCRIPTION";
export const SET_DETAIL_CONFIG_CAPTION = "SET_DETAIL_CONFIG_CAPTION";
export const SET_DETAIL_CONFIG_CTA = "SET_DETAIL_CONFIG_CTA";
export const SET_DETAIL_CONFIG_CTA_BG_COLOR = "SET_DETAIL_CONFIG_CTA_BG_COLOR";
export const SET_DETAIL_CONFIG_CTA_COLOR = "SET_DETAIL_CONFIG_CTA_COLOR";
export const SET_DETAIL_CONFIG_CTA_DOWNLOAD = "SET_DETAIL_CONFIG_CTA_DOWNLOAD";
export const SET_DETAIL_CONFIG_CTA_TEXT = "SET_DETAIL_CONFIG_CTA_TEXT";
export const SET_DETAIL_CONFIG_CTA_TYPE = "SET_DETAIL_CONFIG_CTA_TYPE";
export const SET_DETAIL_CONFIG_CUSTOM_FIELDS =
  "SET_DETAIL_CONFIG_CUSTOM_FIELDS";
export const SET_DETAIL_CONFIG_HIDE = "SET_DETAIL_CONFIG_HIDE";
export const SET_DETAIL_CONFIG_IMAGE = "SET_DETAIL_CONFIG_IMAGE";
export const SET_DETAIL_CONFIG_TITLE = "SET_DETAIL_CONFIG_TITLE";

export const SET_INVALID_SPREADSHEET = "SET_INVALID_SPREADSHEET";

/* List page config */
export const SET_LIST_ITEM_CONFIG = "SET_LIST_ITEM_CONFIG";
export const SET_LIST_ITEM_CONFIG_CAPTION = "SET_LIST_ITEM_CONFIG_CAPTION";
export const SET_LIST_ITEM_CONFIG_CTA = "SET_LIST_ITEM_CONFIG_CTA";
export const SET_LIST_ITEM_CONFIG_CTA_BG_COLOR =
  "SET_LIST_ITEM_CONFIG_CTA_BG_COLOR";
export const SET_LIST_ITEM_CONFIG_CTA_COLOR = "SET_LIST_ITEM_CONFIG_CTA_COLOR";
export const SET_LIST_ITEM_CONFIG_CTA_TEXT = "SET_LIST_ITEM_CONFIG_CTA_TEXT";
export const SET_LIST_ITEM_CONFIG_CTA_TYPE = "SET_LIST_ITEM_CONFIG_CTA_TYPE";
export const SET_LIST_ITEM_CONFIG_CUSTOM_FIELDS =
  "SET_LIST_ITEM_CONFIG_CUSTOM_FIELDS";
export const SET_LIST_ITEM_CONFIG_DESCRIPTION =
  "SET_LIST_ITEM_CONFIG_DESCRIPTION";
export const SET_LIST_ITEM_CONFIG_IMAGE = "SET_LIST_ITEM_CONFIG_IMAGE";
export const SET_LIST_ITEM_CONFIG_TITLE = "SET_LIST_ITEM_CONFIG_TITLE";
export const SET_LIST_ITEM_FILTER_BY = "SET_LIST_ITEM_FILTER_BY";

export const SET_SELECTED_FILTER = "SET_SELECTED_FILTER";

/* Hero section */
export const SET_LIST_ITEM_HERO = "SET_LIST_ITEM_HERO";
export const SET_LIST_ITEM_HERO_IMAGE = "SET_LIST_ITEM_HERO_IMAGE";
export const SET_LIST_ITEM_HERO_SHOW = "SET_LIST_ITEM_HERO_SHOW";
export const SET_LIST_ITEM_HERO_SUBHEADING = "SET_LIST_ITEM_HERO_SUBHEADING";
export const SET_LIST_ITEM_HERO_SUBHEADING_COLOR =
  "SET_LIST_ITEM_HERO_SUBHEADING_COLOR";
export const SET_LIST_ITEM_HERO_SUBHEADING_VALUE =
  "SET_LIST_ITEM_HERO_SUBHEADING_VALUE";
export const SET_LIST_ITEM_HERO_TITLE = "SET_LIST_ITEM_HERO_TITLE";
export const SET_LIST_ITEM_HERO_TITLE_COLOR = "SET_LIST_ITEM_HERO_TITLE_COLOR";
export const SET_LIST_ITEM_HERO_TITLE_VALUE = "SET_LIST_ITEM_HERO_TITLE_VALUE";
export const SET_LIST_ITEM_TOP_CONTENT = "SET_LIST_ITEM_TOP_CONTENT";

/* Footer */
export const SET_FOOTER = "SET_FOOTER";
export const SET_FOOTER_SHOW = "SET_FOOTER_SHOW";
export const SET_FOOTER_DESCRIPTION = "SET_FOOTER_DESCRIPTION";
export const SET_FOOTER_CONTENT = "SET_FOOTER_CONTENT";
export const SET_FOOTER_BACKGROUND_COLOR = "SET_FOOTER_BACKGROUND_COLOR";
export const SET_FOOTER_TEXT_COLOR = "SET_FOOTER_TEXT_COLOR";
export const SET_FOOTER_SOCIAL_URL = "SET_FOOTER_SOCIAL_URL";
export const SET_FOOTER_SOCIAL_SHOW_ICON = "SET_FOOTER_SOCIAL_SHOW_ICON";

/* SEO */
export const SET_META_DESCRIPTION_HOME = "SET_META_DESCRIPTION_HOME";
export const SET_META_DESCRIPTION_ITEM = "SET_META_DESCRIPTION_ITEM";
export const SET_META_TITLE_HOME = "SET_META_TITLE_HOME";
export const SET_META_TITLE_ITEM = "SET_META_TITLE_ITEM";

/* Payment Method Configuration */

export const SET_PAYMENT_CIPHERED_KEYS = "SET_PAYMENT_CIPHERED_KEYS";
export const SET_PAYMENT_CHANGED_KEYS = "SET_PAYMENT_CHANGED_KEYS";
export const SET_PAYMENT_CONFIG_ACTIVE = "SET_PAYMENT_CONFIG_ACTIVE";
export const SET_PAYMENT_CONFIG_PAYMENT_METHOD =
  "SET_PAYMENT_CONFIG_PAYMENT_METHOD";
export const SET_PAYMENT_CONFIG_SELECTED_CURRENCY =
  "SET_PAYMENT_CONFIG_SELECTED_CURRENCY";
export const SET_PAYMENT_CONFIG_TEXT_BUTTON_PAY =
  "SET_PAYMENT_CONFIG_TEXT_BUTTON_PAY";
export const SET_PAYMENT_CONFIG_STRIPE_PUBLIC_KEY =
  "SET_PAYMENT_CONFIG_STRIPE_PUBLIC_KEY";
export const SET_PAYMENT_CONFIG_STRIPE_PRIVATE_KEY =
  "SET_PAYMENT_CONFIG_STRIPE_PRIVATE_KEY";

/* Sheet config */
export const SET_SHEET_DATA = "SET_SHEET_DATA";
export const SET_SHEET_ID = "SET_SHEET_ID";
export const SET_SHEET_ITEMS = "SET_SHEET_ITEMS";
export const SET_SHEET_NAME = "SET_SHEET_NAME";

export const SET_FIELDS = "SET_FIELDS";
export const SET_FILTERS = "SET_FILTERS";

/* Domain settings */
export const SET_SITE_DOMAIN = "SET_SITE_DOMAIN";
export const SET_SITE_SUBDOMAIN = "SET_SITE_SUBDOMAIN";
export const SET_SITE_NAME = "SET_SITE_NAME";

/* Theme settings */
export const SET_THEME = "SET_THEME";
export const SET_THEME_BACKGROUND_COLOR = "SET_THEME_BACKGROUND_COLOR";
export const SET_THEME_FOOTER_COLOR = "SET_THEME_FOOTER_COLOR";
export const SET_THEME_HEADER_COLOR = "SET_THEME_HEADER_COLOR";
export const SET_THEME_FONT = "SET_THEME_FONT";
export const SET_FONTS = "SET_FONTS";
export const SET_FIXED_NAV = "SET_FIXED_NAV";

/* Progressive Web App */
export const SET_PWA_ACTIVE = "SET_PWA_ACTIVE";
export const SET_PWA_NAME = "SET_PWA_NAME";
export const SET_PWA_COLOR = "SET_PWA_COLOR";
export const SET_PWA_ICON = "SET_PWA_ICON";

/* Integrations */
export const SET_GA_ID = "SET_GA_ID";

/* Domains settings */
export const SET_NETLIFY_ID = "SET_NETLIFY_ID";
export const SET_WEBSITE_ID = "SET_WEBSITE_ID";

/* Biew specific settings */
export const SET_BIEW_CONFIG = "SET_BIEW_CONFIG";

/* Ohter */
export const SET_EDIT_MODE = "SET_EDIT_MODE";

export const SET_FAVICON = "SET_FAVICON";

export const SET_IS_DEMO = "SET_IS_DEMO";

export const SET_IS_PRO = "SET_IS_PRO";

export const SET_LOGO = "SET_LOGO";

export const SET_CREATED_AT = "SET_CREATED_AT";

export const SET_VERSION = "SET_VERSION";

export const SET_USER_ADMIN = "SET_USER_ADMIN";
