import Vue from "vue";
import Vuex from "vuex";
import firebase from "@/plugins/firebase";
import builder from "./modules/builder";
import checkout from "./modules/checkout";
import ApiGoogleapis from "@/services/googleapis";
import ApiAuth from "@/services/auth";
import ApiPayments from "@/services/payments";
import db from "@/plugins/firebase/db";
import firebaseAuth from "@/plugins/firebase/auth";
import * as Sentry from "@sentry/browser";
import _isEmpty from "lodash-es/isEmpty";
import {
  formatWithSeparator,
  filterFreeWebsite,
  filterByProSubscription
} from "../utils/functions";
import { currencyConfig } from "../utils/constants";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    googleApiToken: null,
    hasSheetsAccess: false,
    authorizeUrl: null,
    countryCode: "US",
    firstWebsite: "",
    isAdmin: false,
    products: [
      {
        currency: "mxn",
        plan: "Month",
        total: 16000,
        original: 20000,
        priceId: process.env.VUE_APP_STRIPE_MONTHLY_PLAN_PRICE_ID_MX
      },
      {
        currency: "usd",
        plan: "Month",
        total: 800,
        original: 800,
        priceId: process.env.VUE_APP_STRIPE_MONTHLY_PLAN_PRICE_ID
      },
      {
        currency: "mxn",
        plan: "Year",
        total: 144000,
        original: 192000,
        priceId: process.env.VUE_APP_STRIPE_YEARLY_PLAN_PRICE_ID_MX
      },
      {
        currency: "usd",
        plan: "Year",
        total: 7200,
        original: 9600,
        priceId: process.env.VUE_APP_STRIPE_YEARLY_PLAN_PRICE_ID
      }
    ],
    websites: [],
    numeroProDisponible: 0,
    loading: false
  },
  mutations: {
    ["SET_USER"](state, user) {
      state.user = user;
    },
    ["SET_GOOGLE_API_TOKEN"](state, token) {
      state.googleApiToken = token;
    },
    ["SET_SHEET_ACCESS"](state, sheet) {
      state.hasSheetsAccess = sheet;
    },
    ["SET_IS_ADMIN"](state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    ["SET_AUTHORIZE_URL"](state, authorizeUrl) {
      state.authorizeUrl = authorizeUrl;
    },
    ["SET_COUNTRY_CODE"](state, countryCode) {
      state.countryCode = countryCode;
    },
    ["SET_FIRST_WEBSITE"](state, firstWebsite) {
      state.firstWebsite = firstWebsite;
    },
    ["SET_WEBSITES"](state, websites) {
      state.websites = websites;
    },
    ["SET_REPLACE_WEBSITE"](state, website) {
      const updatedWebsites = state.websites.map(item => {
        if (item.websiteId === website.websiteId) return website;
        return item;
      });
      state.websites = updatedWebsites;
    },
    ["SET_LOADING"](state, value) {
      state.loading = value;
    }
  },
  actions: {
    async signin({ commit }) {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().languageCode = "es";

      firebase
        .auth()
        .getRedirectResult()
        .then(result => {
          if (result.credential) {
            const token = result.credential.accessToken;
            commit("SET_GOOGLE_API_TOKEN", token);
            const user = result.user;
            const { uid } = user;
            commit("SET_USER", user);
            ApiAuth.setUID(uid);
          }
        })
        .catch(error => {
          Sentry.captureException(error);
        });

      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          return firebase.auth().signInWithPopup(provider);
        })
        .catch(error => {
          Sentry.captureException(error);
        });
    },
    setLoadingPage({ commit }, value) {
      if ("boolean" === typeof value) {
        commit("SET_LOADING", value);
      }
    },
    async getUser({ commit, dispatch, state }, { uid, email }) {
      try {
        const user = await db
          .collection("users")
          .doc(uid)
          .get();
        const userData = user.exists ? user.data() : null;

        if (userData?.googleApisTokens && userData?.googleApisTokens.write) {
          commit("SET_SHEET_ACCESS", true);
        } else {
          dispatch("googleapisOauth", { uid, email, forceOpen: true });
        }

        if (!userData?.profile) {
          await dispatch("updateUserProfile", { uid: user.id, data: userData });
        }

        if (!userData?.stripeCustomerId) {
          dispatch("createStripeCustomer", { uid, email });
        } else {
          const user = { ...state.user };
          user.stripeCustomerId = userData.stripeCustomerId;
          commit("SET_USER", user);
        }

        if (userData?.firstWebsite) {
          commit("SET_FIRST_WEBSITE", userData.firstWebsite);
        }

        if (userData) {
          const user = { ...state.user };

          if (userData.agency) {
            user.agency = true;
          }

          if (userData.websitesLimit) {
            user.websitesLimit = userData.websitesLimit;
          }
          commit("SET_USER", user);
        }

        if (userData) {
          const data = {};
          if (!userData?.agency) {
            data.websitesLimit = 2;
          }

          if (!_isEmpty(data)) {
            await dispatch("updateUser", { uid, data });
          }
        }

        if (process.env.NODE_ENV !== "development") {
          dispatch("getLocation");
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    async googleapisOauth({ commit }, { uid, email, forceOpen = false }) {
      try {
        const res = await ApiGoogleapis.oauth2({ params: { uid, email } });
        commit("SET_AUTHORIZE_URL", res.data.authorizeUrl);
        if (forceOpen) window.open(res.data.authorizeUrl);
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    async updateUser(_, { uid, data }) {
      try {
        const docRef = db.collection("users").doc(uid);
        if (data) {
          await docRef.update(data);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    async updateUserProfile(_, { uid, data }) {
      try {
        const docRef = db.collection("users").doc(uid);
        const profileData = JSON.parse(
          JSON.stringify(firebaseAuth.currentUser.providerData[0])
        );
        if (data) {
          await docRef.update({ profile: profileData });
        } else {
          await docRef.set({ websitesLimit: 2, profile: profileData });
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    async createStripeCustomer({ commit, state }, { uid, email }) {
      try {
        const res = await ApiPayments.createCustomer({ uid, email });
        const customer = res.data;
        const user = { ...state.user };
        user.stripeCustomerId = customer.id;
        commit("SET_USER", user);
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    async getLocation({ commit }) {
      try {
        const token = process.env.VUE_APP_ABSTRACT_IP_GEOLOCATION_KEY;
        const res = await fetch(
          `https://ipgeolocation.abstractapi.com/v1/?api_key=${token}`
        );
        const location = await res.json();
        commit("SET_COUNTRY_CODE", location.country_code);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    setWebsites({ commit }, websites) {
      if (Array.isArray(websites)) commit("SET_WEBSITES", websites);
    }
  },
  getters: {
    isAgency(state) {
      return state.user.agency ? true : false;
    },
    formatedProducts(state) {
      let currency = state.countryCode === "MX" ? "mxn" : "usd";
      return state.products
        .map(product => {
          const { total, original } = product;
          let formatedTotal = formatWithSeparator(total);
          let formatedOriginal = formatWithSeparator(original);
          const currencyFormat = new Intl.NumberFormat(
            ...currencyConfig[currency]
          );
          return {
            ...product,
            formatedTotal: currencyFormat.format(formatedTotal),
            formatedOriginal: currencyFormat.format(formatedOriginal)
          };
        })
        .filter(product => product.currency === currency);
    },
    freeWebsites: state => {
      return state.websites.filter(filterFreeWebsite);
    },
    proWebsites: state => {
      return state.websites.filter(filterByProSubscription);
    },
    totalFreeSites: state => {
      return state.websites.filter(filterFreeWebsite).length;
    },
    totalSites: state => {
      return state.websites.length;
    },
    totalProSites: state => {
      return state.websites.filter(filterByProSubscription).length;
    },
    showCardProducts: (state, getters) => (checked, i18n) => {
      const products = getters.formatedProducts.map(product => {
        const obj = {
          ...product,
          title: "",
          id: "",
          price: product.formatedTotal,
          savePrice: "",
          checkedPlan: false,
          priceId: product.priceId,
          priceWithCurrency: `${product.formatedTotal} ${String(
            product.currency
          ).toUpperCase()}`
        };
        if (product.original !== product.total) {
          let formatedTotal = formatWithSeparator(product.total);
          let formatedOriginal = formatWithSeparator(product.original);
          let savePrice =
            parseFloat(formatedOriginal) - parseFloat(formatedTotal);
          if (Number.isInteger(savePrice)) {
            savePrice = `${savePrice}.00 ${String(
              product.currency
            ).toUpperCase()}`;
          }
          const formatedSavePrice = `(${i18n.save_price.replace(
            /{amount}/,
            savePrice
          )})`;
          obj.savePrice = formatedSavePrice;
        }
        if (product.plan === "Year") {
          obj.title = i18n.title_pro_website_per_year;
          obj.id = "id-plan-card-yearly";
        }
        if (product.plan === "Month") {
          obj.title = i18n.title_pro_website_per_month;
          obj.id = "id-plan-card-monthly";
        }
        obj.checkedPlan = checked === product.plan;
        return obj;
      });
      return products;
    }
  },
  modules: {
    builder,
    checkout
  }
});
