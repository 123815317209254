import * as mutation from "./mutation-types";
import * as Sentry from "@sentry/browser";
import state from "./state";
import ApiSheets from "@/services/sheets";
import ApiSites from "@/services/sites";
import router from "@/router";
import auth from "@/plugins/firebase/auth";
import { uploadTask } from "@/plugins/firebase/storage";
import ActionTypes from "@/constants/action-types";
import FieldTypes from "@/constants/field-types";

export default {
  async resetState({ commit }) {
    try {
      commit("reset");
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },

  async createWebsite({ commit, rootState, rootGetters }, { options }) {
    try {
      const isAgency = rootGetters.isAgency;
      let res = null;

      if (isAgency) {
        res = await ApiSites.create({
          uid: rootState.user.uid,
          options
        });
      } else {
        res = await ApiSites.createV2({
          uid: rootState.user.uid,
          options
        });
      }

      const {
        demo,
        websiteId,
        subdomain,
        domain,
        netlifyId,
        firstWebsite,
        createdAt,
        version,
        biewConfig
      } = res.data;

      commit(mutation.SET_WEBSITE_ID, websiteId);
      commit(mutation.SET_SITE_SUBDOMAIN, subdomain);
      commit(mutation.SET_SITE_DOMAIN, domain);
      commit(mutation.SET_CREATED_AT, createdAt);
      commit(mutation.SET_VERSION, version);

      if (netlifyId) {
        commit(mutation.SET_NETLIFY_ID, netlifyId);
      }

      if (biewConfig) {
        commit(mutation.SET_BIEW_CONFIG, biewConfig);
      }

      if (demo) {
        commit(mutation.SET_IS_DEMO, demo);
      }

      if (rootState.user.firstWebsite) {
        commit("SET_FIRST_WEBSITE", firstWebsite, { root: true });
      }
      router.push({ name: "builder" }).catch(() => {});
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },

  async getSheet({ commit, state }, { sheetId }) {
    try {
      const params = {};

      if (state.listItemConfig.filterByField) {
        params.filterBy = state.listItemConfig.filterByField?.name;
      }

      const response = (await ApiSheets.getSheet(sheetId, { params })).data;
      const { fields, filters, sheetName } = response;
      let titleField, descriptionField, captionField, imageField, filterByField;
      const ctaField = {
        field: {
          name: "",
          type: ""
        },
        text: "",
        bgColor: "#3398db",
        color: "#ffffff",
        type: ActionTypes.NUM_LINK
      };
      const hero = {
        show: false,
        subheading: {
          value: ""
        },
        title: {
          value: ""
        },
        image: {
          value: ""
        }
      };
      const topContent = "";

      titleField = fields.find(el => el.type === FieldTypes.STRING);
      descriptionField =
        fields.find(
          el => el.type === FieldTypes.STRING && el.name !== titleField.name
        ) || "";
      captionField =
        fields.find(
          el =>
            (el.type === FieldTypes.LINK || el.type === FieldTypes.STRING) &&
            el.name !== titleField.name &&
            el.name !== descriptionField.name
        ) || "";

      const hasImage = fields.find(el => {
        const fieldName = el.name.toLowerCase();
        const isImage = ["image", "photo"].indexOf(fieldName) > -1;
        if (el.type === FieldTypes.IMAGE) {
          return true;
        }
        if (el.type === FieldTypes.URL && isImage) {
          return true;
        }
        return false;
      });

      if (hasImage) {
        imageField = hasImage;
      }

      let imageConfig = imageField;
      if (imageConfig) {
        const imageFields = Array.isArray(imageConfig.name)
          ? imageConfig.name
          : [imageConfig.name];
        imageConfig = { name: imageFields, type: imageField?.type || "URL" };
      } else {
        imageConfig = {
          name: [],
          type: "URL"
        };
      }

      if (params.filterBy) {
        filterByField = fields.find(el => el.name === params.filterBy) || "";
      } else {
        filterByField = "";
      }

      commit(mutation.SET_SHEET_ID, sheetId);
      commit(mutation.SET_SHEET_NAME, sheetName);
      commit(mutation.SET_FILTERS, filters);
      commit(mutation.SET_SELECTED_FILTER, null);

      /* If the edited items doesn't have any fields configuration even in edit mode
       * then add the fields */
      const hasListItemConfig =
        Object.entries(state.listItemConfig).some(([, value]) => value) ||
        state.listItemConfig.ctaField.text;
      const hasDetailConfig =
        Object.entries(state.detailConfig).some(([, value]) => value) ||
        state.detailConfig.ctaField.text;

      if (!state.editMode || !hasListItemConfig) {
        commit(mutation.SET_LIST_ITEM_CONFIG, {
          captionField,
          ctaField,
          customFields: [],
          descriptionField,
          filterByField,
          hero,
          imageField: imageConfig,
          titleField,
          topContent
        });
      }

      if (!state.editMode || !hasDetailConfig) {
        commit(mutation.SET_DETAIL_CONFIG, {
          captionField,
          ctaField,
          customFields: [],
          descriptionField,
          imageField: imageConfig,
          titleField
        });
      }
      commit(mutation.SET_FIELDS, fields);
      commit(mutation.SET_SHEET_ITEMS, response.items);
      commit(mutation.SET_INVALID_SPREADSHEET, false);
    } catch (error) {
      commit(mutation.SET_INVALID_SPREADSHEET, true);
      Sentry.captureException(error);
      throw error;
    }
  },

  async assignDomain({ dispatch, state }, subdomain) {
    try {
      // Determine if subdomain already exists¡
      const websiteRefMongo = await ApiSites.updateDomain({
        uid: auth.currentUser.uid,
        domain: state.domain,
        subdomain: subdomain,
        websiteId: state.websiteId
      });
      if (websiteRefMongo.data.subdomain === subdomain) {
        return { message: "You already own this subdomain" };
      } else {
        let domain = state.domain;
        if (state.domain.endsWith(".getbiew.com")) {
          domain = `${subdomain}.getbiew.com`;
        } else if (state.domain.endsWith(".biew.site")) {
          domain = `${subdomain}.biew.site`;
        }
        return dispatch("updateCustomDomain", { domain, subdomain });
      }
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async setEditWebsite({ commit, dispatch }, website) {
    try {
      const {
        demo,
        id,
        name,
        productOptions,
        sheet,
        isPro,
        listView,
        logo,
        netlifyId,
        detailView,
        domain,
        gaId,
        favicon,
        metaFields,
        checkout = {},
        createdAt,
        pwa = {},
        theme = {},
        footer = {},
        biewConfig,
        paymentCfg = state.paymentCfg,
        version,
        websiteId,
        fixedNav
      } = website;
      dispatch("setLoadingPage", true, { root: true });
      const subdomain = domain.split(".")[0];
      const checkoutForm = checkout?.form || {};
      commit(mutation.SET_EDIT_MODE, true);
      commit(mutation.SET_WEBSITE_ID, websiteId || id);
      commit(mutation.SET_NETLIFY_ID, netlifyId);
      commit(mutation.SET_SITE_DOMAIN, domain);
      commit(mutation.SET_IS_PRO, isPro ?? false);
      commit(mutation.SET_GA_ID, gaId || "");
      commit(mutation.SET_LOGO, logo || "");
      commit(mutation.SET_FAVICON, favicon || "");
      commit(mutation.SET_CREATED_AT, createdAt || null);
      commit(mutation.SET_IS_DEMO, demo || false);
      commit(mutation.SET_FIXED_NAV, fixedNav || false);

      /* Checkout */
      commit(
        mutation.SET_CHECKOUT_FORM_NAME,
        checkoutForm.name || { show: true, required: true }
      );
      commit(
        mutation.SET_CHECKOUT_FORM_EMAIL,
        checkoutForm.email || { show: false, required: false }
      );
      commit(
        mutation.SET_CHECKOUT_FORM_ADDRESS,
        checkoutForm.address || { show: false, required: false }
      );
      commit(
        mutation.SET_CHECKOUT_FORM_BRANCH,
        checkoutForm.branch || { show: false, required: false }
      );
      commit(
        mutation.SET_CHECKOUT_FORM_COUNTRY,
        checkoutForm.country || { show: false, required: false }
      );
      commit(
        mutation.SET_CHECKOUT_FORM_NOTES,
        checkoutForm.notes || { show: true, required: false }
      );
      commit(
        mutation.SET_CHECKOUT_FORM_GOOGLEMAPS,
        checkoutForm.googleMaps || false
      );
      commit(
        mutation.SET_CHECKOUT_FORM_PAYMENT_METHODS,
        checkoutForm.paymentMethods || {
          cash: false,
          creditCard: false
        }
      );
      commit(
        mutation.SET_CHECKOUT_FORM_PHONE_NUMBER,
        checkoutForm.phoneNumber || { show: false, required: false }
      );
      commit(
        mutation.SET_CHECKOUT_FORM_PICKUP,
        checkoutForm.pickup || { available: true }
      );
      commit(
        mutation.SET_CHECKOUT_FORM_DELIVERY,
        checkoutForm.delivery || { available: true, cost: "" }
      );
      commit(
        mutation.SET_CHECKOUT_FORM_CITY,
        checkoutForm.city || { show: false, required: false }
      );
      commit(
        mutation.SET_CHECKOUT_FORM_STATE,
        checkoutForm.state || { show: false, required: false }
      );
      commit(
        mutation.SET_CHECKOUT_FORM_ZIP_CODE,
        checkoutForm.zipCode || { show: false, required: false }
      );
      commit(mutation.SET_CHECKOUT_HELLO_MESSAGE, checkout.helloMessage);
      commit(
        mutation.SET_CHECKOUT_MESSAGE_ENDING,
        checkout.messageEnding || ""
      );
      commit(mutation.SET_CHECKOUT_PHONE_NUMBER, checkout.phoneNumber || "");
      commit(mutation.SET_CHECKOUT_PRICE_FIELD, checkout.priceField || "");

      commit(mutation.SET_CHECKOUT_SET_BRANCHES, checkout.branches || []);

      // Variants
      commit(mutation.SET_PRODUCT_OPTIONS, productOptions || []);

      // Single page config
      commit(mutation.SET_DETAIL_CONFIG_CAPTION, detailView?.caption || "");
      commit(
        mutation.SET_DETAIL_CONFIG_DESCRIPTION,
        detailView?.description || ""
      );
      commit(mutation.SET_DETAIL_CONFIG_CTA, detailView?.cta?.field || null);
      commit(
        mutation.SET_DETAIL_CONFIG_CTA_BG_COLOR,
        detailView?.cta?.bgColor || "#3398db"
      );
      commit(
        mutation.SET_DETAIL_CONFIG_CTA_COLOR,
        detailView?.cta?.color || "#ffffff"
      );
      commit(
        mutation.SET_DETAIL_CONFIG_CTA_DOWNLOAD,
        detailView?.cta?.download || false
      );
      commit(mutation.SET_DETAIL_CONFIG_CTA_TEXT, detailView?.cta?.text || "");
      commit(
        mutation.SET_DETAIL_CONFIG_CTA_TYPE,
        detailView?.cta?.type || ActionTypes.NUM_LINK
      );
      commit(
        mutation.SET_DETAIL_CONFIG_CUSTOM_FIELDS,
        detailView?.custom || []
      );
      commit(mutation.SET_DETAIL_CONFIG_HIDE, detailView?.hide || false);

      /* Payment Config */
      commit(mutation.SET_PAYMENT_CONFIG_ACTIVE, paymentCfg.active);
      commit(
        mutation.SET_PAYMENT_CONFIG_PAYMENT_METHOD,
        paymentCfg.paymentMethod
      );
      commit(
        mutation.SET_PAYMENT_CONFIG_SELECTED_CURRENCY,
        paymentCfg.currency
      );
      commit(
        mutation.SET_PAYMENT_CONFIG_TEXT_BUTTON_PAY,
        paymentCfg.textButtonPay
      );

      commit(mutation.SET_PAYMENT_CIPHERED_KEYS, paymentCfg.cipheredKeys);
      commit(
        mutation.SET_PAYMENT_CONFIG_STRIPE_PRIVATE_KEY,
        paymentCfg.stripePrivateKey
      );
      commit(
        mutation.SET_PAYMENT_CONFIG_STRIPE_PUBLIC_KEY,
        paymentCfg.stripePublicKey
      );

      // Parse image field into array of fields
      let detailImageConfig = detailView?.image;
      if (detailImageConfig) {
        const imageFields = Array.isArray(detailImageConfig.name)
          ? detailImageConfig.name
          : [detailImageConfig.name];
        detailImageConfig = {
          name: imageFields,
          type: imageFields[0]?.type || "URL"
        };
      }

      commit(
        mutation.SET_DETAIL_CONFIG_IMAGE,
        detailImageConfig || { name: [], type: "URL" }
      );

      commit(mutation.SET_DETAIL_CONFIG_TITLE, detailView?.title || "");

      commit(mutation.SET_LIST_ITEM_CONFIG_CAPTION, listView?.caption || "");
      commit(
        mutation.SET_LIST_ITEM_CONFIG_DESCRIPTION,
        listView?.description || ""
      );
      commit(mutation.SET_LIST_ITEM_FILTER_BY, listView?.filterBy || "");

      // Parse image field into array of fields
      let listImageConfig = listView?.image;
      if (listImageConfig) {
        const imageFields = Array.isArray(listImageConfig.name)
          ? listImageConfig.name
          : [listImageConfig.name];
        listImageConfig = {
          name: imageFields,
          type: imageFields[0]?.type || "URL"
        };
      }

      commit(
        mutation.SET_LIST_ITEM_CONFIG_IMAGE,
        listImageConfig || { name: [], type: "URL" }
      );
      commit(mutation.SET_LIST_ITEM_CONFIG_TITLE, listView?.title || "");

      commit(
        mutation.SET_LIST_ITEM_CONFIG_CTA_TYPE,
        listView?.cta?.type || ActionTypes.NUM_LINK
      );
      commit(
        mutation.SET_LIST_ITEM_CONFIG_CTA,
        listView?.cta?.field || {
          name: "",
          type: ""
        }
      );
      commit(
        mutation.SET_LIST_ITEM_CONFIG_CTA_BG_COLOR,
        listView?.cta?.bgColor || "#3398db"
      );
      commit(
        mutation.SET_LIST_ITEM_CONFIG_CTA_COLOR,
        listView?.cta?.color || "#ffffff"
      );
      commit(mutation.SET_LIST_ITEM_CONFIG_CTA_TEXT, listView?.cta?.text || "");
      commit(
        mutation.SET_LIST_ITEM_CONFIG_CUSTOM_FIELDS,
        listView?.custom || []
      );

      /* Progressive Web App */
      commit(mutation.SET_PWA_ACTIVE, pwa?.active || "");
      commit(mutation.SET_PWA_NAME, pwa?.name || "");
      commit(mutation.SET_PWA_COLOR, pwa?.color || "");
      commit(mutation.SET_PWA_ICON, pwa?.icon || "");

      /* Home Page Hero */
      commit(
        mutation.SET_LIST_ITEM_HERO,
        listView?.hero || {
          show: false,
          subheading: {
            value: "",
            color: "#ffffff"
          },
          title: {
            value: "",
            color: "#ffffff"
          },
          image: {
            value: ""
          }
        }
      );

      /* Top Content */
      commit(mutation?.SET_LIST_ITEM_TOP_CONTENT, listView?.topContent || "");

      /* Footer */
      commit(mutation?.SET_FOOTER, footer);

      /* Theme */
      commit(mutation?.SET_THEME, theme);

      /* Meta tags */
      commit(
        mutation.SET_META_DESCRIPTION_HOME,
        metaFields?.homeDescription || ""
      );
      commit(
        mutation.SET_META_DESCRIPTION_ITEM,
        metaFields?.itemDescription || ""
      );

      commit(mutation.SET_META_TITLE_HOME, metaFields?.homeTitle || "");
      commit(mutation.SET_META_TITLE_ITEM, metaFields?.itemTitle || "");
      commit(mutation.SET_SHEET_ID, sheet?.id || null);
      commit(mutation.SET_SHEET_NAME, sheet?.sheet || null);
      commit(mutation.SET_SITE_NAME, name);
      commit(mutation.SET_SITE_SUBDOMAIN, subdomain);

      /* Biew specific settings */
      commit(mutation.SET_BIEW_CONFIG, biewConfig || {});

      /* Version */
      commit(mutation.SET_VERSION, version);
      dispatch("setLoadingPage", false, { root: true });
      router.push({ name: "builder" });
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },

  async setFooterSocialUrl({ commit, state }, { key, url }) {
    if (key in state.footer.socials) {
      commit(mutation.SET_FOOTER_SOCIAL_URL, { key, url });
    }
  },

  async setFooterSocialShowIcon({ commit, state }, { key, show }) {
    if (key in state.footer.socials) {
      commit(mutation.SET_FOOTER_SOCIAL_SHOW_ICON, { key, show });
    }
  },

  async updateCustomDomain(
    { commit, state, rootState },
    { domain, subdomain }
  ) {
    try {
      let res = null;
      if (!state.netlifyId) {
        res = await ApiSites.updateDomainV2({
          uid: rootState.user.uid,
          domain: domain,
          subdomain: subdomain,
          websiteId: state.websiteId,
          distributionId: state.biewConfig.distributionId
        });
      } else {
        res = await ApiSites.updateDomain({
          uid: rootState.user.uid,
          netlifyId: state.netlifyId,
          domain: domain,
          subdomain: subdomain,
          websiteId: state.websiteId
        });
      }

      commit(mutation.SET_SITE_DOMAIN, domain);
      commit(mutation.SET_SITE_SUBDOMAIN, subdomain);
      return res;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },

  async getSheetItems({ commit, state }) {
    try {
      const res = await ApiSheets.getSheetItems({
        sheetId: state.sheetId
      });
      commit(mutation.SET_SHEET_ITEMS, res.data.items);
      return res;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },

  async updloadHeaderImage({ commit, rootState }, image) {
    try {
      const userId = rootState.user.uid;
      const path = `users/${userId}/images/${image.name}`;
      const storageUrl = await uploadTask({ image, path });

      commit(mutation.SET_LIST_ITEM_HERO_IMAGE, { value: storageUrl });

      return storageUrl;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },

  async updloadPwaImage({ commit, rootState }, image) {
    try {
      const userId = rootState.user.uid;
      const path = `users/${userId}/images/pwa-${image.name}`;
      const storageUrl = await uploadTask({ image, path });

      commit(mutation.SET_PWA_ICON, storageUrl);

      return storageUrl;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },

  async findUserWebsites({ commit }, userEmail) {
    try {
      const { data } = await ApiSites.findUserWebsites(userEmail);
      commit(mutation.SET_USER_ADMIN, data);
      return data;
    } catch (error) {
      return null;
    }
  },
  async findMyWebsites(uid) {
    try {
      const { data } = await ApiSites.findMyWebsites(uid);
      return data;
    } catch (error) {
      return null;
    }
  },
  async duplicate(website) {
    try {
      const { data } = await ApiSites.duplicate(website);
      return data;
    } catch (error) {
      return null;
    }
  }
};
